// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { themeSwicther } from "https://framerusercontent.com/modules/xPIcqezjj5hpxtXhfRKu/wIEwUZy8jkl6Wft1lkLz/Dsa.js";
const MotionButtonThemeSwicther = themeSwicther(motion.button);

const cycleOrder = ["r9puLmyRk", "ivqlHUv7w"];

const serializationHash = "framer-o83P4"

const variantClassNames = {ivqlHUv7w: "framer-v-1umcv39", r9puLmyRk: "framer-v-1v3b80c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "r9puLmyRk", "Variant 2": "ivqlHUv7w"}

const getProps = ({height, id, tap, tap2, title, width, ...props}) => { return {...props, tg80T0chk: title ?? props.tg80T0chk ?? "DARK MODE", uHJdmi09c: tap2 ?? props.uHJdmi09c, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "r9puLmyRk", VkCjCtEzA: tap ?? props.VkCjCtEzA} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;tap2?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, VkCjCtEzA, uHJdmi09c, tg80T0chk, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "r9puLmyRk", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap8lbqmn = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (VkCjCtEzA) {
const res = await VkCjCtEzA(...args);
if (res === false) return false;
}
setVariant("ivqlHUv7w")
})

const onTap1oyr47e = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (VkCjCtEzA) {
const res = await VkCjCtEzA(...args);
if (res === false) return false;
}
setVariant("r9puLmyRk")
})

const onTap575b32 = activeVariantCallback(async (...args) => {
if (uHJdmi09c) {
const res = await uHJdmi09c(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><MotionButtonThemeSwicther {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1v3b80c", className, classNames)} data-framer-name={"Variant 1"} data-highlight data-reset={"button"} initial={variant} layoutDependency={layoutDependency} layoutId={"r9puLmyRk"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap8lbqmn} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ivqlHUv7w: {"data-framer-name": "Variant 2", onTap: onTap1oyr47e}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7U3BhY2UgR3JvdGVzay1yZWd1bGFy", "--framer-font-family": "\"Space Grotesk\", \"Space Grotesk Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "23px", "--framer-text-alignment": "right", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-a272c214-fcc9-48fc-bfef-bcf3cc4f7dd5, rgb(196, 196, 196)))"}}>DARK MODE</motion.p></React.Fragment>} className={"framer-cyravn"} data-highlight fonts={["GF;Space Grotesk-regular"]} layoutDependency={layoutDependency} layoutId={"ajogimg19"} onTap={onTap575b32} style={{"--extracted-r6o4lv": "var(--token-a272c214-fcc9-48fc-bfef-bcf3cc4f7dd5, rgb(196, 196, 196))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={tg80T0chk} verticalAlignment={"center"} withExternalLayout/><motion.div className={"framer-1d4lpp3"} layoutDependency={layoutDependency} layoutId={"kyY1rfAnU"} style={{backgroundColor: "var(--token-a272c214-fcc9-48fc-bfef-bcf3cc4f7dd5, rgb(28, 28, 28))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}}/></MotionButtonThemeSwicther></Transition>
</LayoutGroup>)

});

const css = [".framer-o83P4[data-border=\"true\"]::after, .framer-o83P4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-o83P4.framer-1bbqphr, .framer-o83P4 .framer-1bbqphr { display: block; }", ".framer-o83P4.framer-1v3b80c { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-o83P4 .framer-cyravn { -webkit-user-select: none; cursor: pointer; flex: none; height: 40px; pointer-events: none; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-o83P4 .framer-1d4lpp3 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 12px); overflow: hidden; position: relative; width: 12px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-o83P4.framer-1v3b80c { gap: 0px; } .framer-o83P4.framer-1v3b80c > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-o83P4.framer-1v3b80c > :first-child { margin-left: 0px; } .framer-o83P4.framer-1v3b80c > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 121
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ivqlHUv7w":{"layout":["auto","auto"]}}}
 * @framerVariables {"VkCjCtEzA":"tap","uHJdmi09c":"tap2","tg80T0chk":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerZH4lCapI8: React.ComponentType<Props> = withCSS(Component, css, "framer-o83P4") as typeof Component;
export default FramerZH4lCapI8;

FramerZH4lCapI8.displayName = "Variant 1";

FramerZH4lCapI8.defaultProps = {height: 40, width: 121};

addPropertyControls(FramerZH4lCapI8, {variant: {options: ["r9puLmyRk", "ivqlHUv7w"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, VkCjCtEzA: {title: "Tap", type: ControlType.EventHandler}, uHJdmi09c: {title: "Tap 2", type: ControlType.EventHandler}, tg80T0chk: {defaultValue: "DARK MODE", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerZH4lCapI8, [{family: "Space Grotesk", style: "normal", url: "https://fonts.gstatic.com/s/spacegrotesk/v16/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7oUUsmNsFjTDJK.woff2", weight: "400"}])